import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Angulartics2Module } from 'angulartics2';
import { LanguageComponent } from './components/language.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        Angulartics2Module,
        MatMenuModule
     ],
    declarations: [
        LanguageComponent,
    ],
    exports: [
        LanguageComponent,
    ]
})
export class LanguageModule {}