<mat-menu #languageMenu="matMenu">
  <button mat-menu-item (click)="select('en-US');"
      angulartics2On="click" 
      angularticsAction="Button Pressed"
      angularticsCategory="Language selector"
      angularticsLabel="EN">
    <mat-icon svgIcon="flag-gb"></mat-icon>
    English
  </button>
  <button mat-menu-item (click)="select('et');"
      angulartics2On="click" 
      angularticsAction="Button Pressed"
      angularticsCategory="Language selector"
      angularticsLabel="ET">
    <mat-icon svgIcon="flag-ee"></mat-icon>
    Eesti
  </button>
  <!-- button mat-menu-item (click)="select('ru');">
    <mat-icon svgIcon="flag-ru"></mat-icon>
  </button-->
</mat-menu>
<button mat-icon-button [matMenuTriggerFor]="languageMenu"
    angulartics2On="click" 
    angularticsAction="Button Pressed"
    angularticsCategory="Language selector"
    angularticsLabel="Expand">
  <mat-icon [svgIcon]="'flag-' + selectedLanguageCountry"></mat-icon>
</button>